'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, a, span, img, div,
	table, thead, tbody, tr, td, th, form, input, button, label
} = require('iblokz-snabbdom-helpers');
// widgets
const calendar = require('./calendar');

const {obj} = require('iblokz-data');
const moment = require('moment');

const poster = event => section('.poster', [
	img(`[src="${event.poster}"]`),
	a(`.title[href="${event.href}"]`, [
		moment(event.dateTime).format('DD.MM H:mm'),
		' ',
		event.title
	])
]);

module.exports = ({state, actions}) => section('.right-pane', [
	section([
		ul([
			li([a('[href="https://fb.com/groups/musictechbg"][target="_blank"]', [
				i('.fa.fa-facebook-official'),
				'Facebook Група MusicTechBG'
			])]),
			li([a('[href="https://www.youtube.com/channel/UCphmUQ_1b0g9sIhmo505nAg"][target="_blank"]', [
				i('.fa.fa-youtube'),
				'Youtube Канал MusicTechBG'
			])]),
			li([a('[href="https://meetup.com/MusicTechBG"][target="_blank"]', [
				i('.fa.fa-meetup'),
				'Събития в Meetup.com'
			])])
		])
	]),
	// poster({
	// 	dateTime: '2019-09-21T14:00',
	// 	duration: '5h',
	// 	title: 'MusicTech MIDI Party',
	// 	poster: 'assets/posters/MusicTechMIDIParty.png',
	// 	href: 'http://fb.com/events/2665191436858443/'
	// }),
	calendar({state, actions})
]);
