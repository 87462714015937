'use strict';

// dom
const {
	h1, a, div,
	section, button, span,
	img, ul, li
} = require('iblokz-snabbdom-helpers');
// components
const header = require('./header');
const content = require('./content');
const rightPane = require('./right-pane');

module.exports = ({state, actions}) => section('.layout', [].concat(
	content({state, actions}),
	state.router.path[0] !== 'admin' ? rightPane({state, actions}) : []
));
