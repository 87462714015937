'use strict';

// dom
const {
	h1, a, div, header, p,
	section, button, span, ul, li
} = require('iblokz-snabbdom-helpers');
// components
const form = require('../../../comp/form');

// lib
const {obj} = require('iblokz-data');

const excludeFields = [
	'createdBy', 'createdOn'
];

module.exports = ({state, actions}) => form({
	sel: state.router.pageId === 'new' ? '.create' : '.edit',
	schema: obj.filter(
		state.rest.collections[state.router.path[1]].schema,
		(field, value) => (
			// console.log(field, excludeFields),
			excludeFields.indexOf(field) === -1
		)
	),
	doc: state[state.router.path[1]].doc,
	focused: state[state.router.path[1]].focused || false,
	actions: {
		update: (field, value) => (
			console.log('update', field, value),
			actions.set([state.router.path[1]], {
				focused: field[0],
				doc: {
					...state[state.router.path[1]].doc,
					[field]: value
				}
			})
		),
		save: ({data, ev}) => (
			actions[state.router.path[1]].save(
				obj.map(
					Object.assign(state[state.router.path[1]].doc, data),
					(key, value) => ['content', 'article', 'post', 'text'].indexOf(key) > -1
						? value.content : value
				),
				state.auth.token,
				() => actions.router.go(state.router.path.slice(0, 2).join('.'))
			)
		),
		cancel: ({ev}) => actions.router.go(state.router.path.slice(0, 2).join('.'))
	}
});
