'use strict';

// dom
const {
	h1, a, div, header, p,
	section, button, span, ul, li
} = require('iblokz-snabbdom-helpers');

module.exports = ({state, actions}) => ul('.breadcrumb', [].concat(
	state.router.path.map((page, index, arr) =>
		li({
			style: {
				'font-size': `${
					((index === arr.length - 1) ? 1.2 : 1) * (page.length > 8 ? 0.8 : 1.2)
				}em`
			}
		}, a(`[href="#/${arr.slice(0, index + 1).join('/')}"]`, page))
	)
));
