'use strict';

const {obj, arr} = require('iblokz-data');

// namespaces=

// initial
const initial = {
	// main, log-in, null
	menu: null
};

// actions
// default
const ping = () => state => state;
const set = (key, value) => state => obj.patch(state, key, value);
const toggle = key => state => obj.patch(state, key, !obj.sub(state, key));
const arrToggle = (key, value) => state =>
	obj.patch(state, key,
		arr.toggle(obj.sub(state, key), value)
	);
// custom
const toggleMenu = menu => state => Object.assign({}, state, {
	menu: state.menu === menu ? null : menu
});

module.exports = {
	initial,
	ping,
	set,
	toggle,
	arrToggle,
	toggleMenu
};
