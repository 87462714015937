'use strict';

// dom
const {
	h1, a, div, header,
	section, button, span,
	ul, li, br, img, i,
	table, thead, tbody, tr, th, td,
	form, input, label, fieldset, legend,
	select, option, textarea
} = require('iblokz-snabbdom-helpers');

// util
const {obj, str, arr, fn: {pipe}} = require('iblokz-data');
const dom = require('../../../util/dom');
const fileUtil = require('../../../util/file');

const set = (o, k, v) => Object.assign(o, {[k]: v});
const trim = s => s.trim();

const fieldToTitle = field => str.fromCamelCase(field, '-')
	.split('-').map(word => str.capitalize(word)).join(' ');

module.exports = ({sel = '.field', field, value = [], update = () => {}}) => div('.field', [
	field.name.split('.').length === 1 ? label([
		fieldToTitle(field.name.split('.').pop()),
		': '
	]) : '',
	div(`.col-sm-10`, [].concat(
		value.map(image => div(`.col-xs-4.col-md-2`,
			a(`.thumbnail[title=""]`, {
				style: {
					width: '100%',
					textAlign: 'center'
				},
				on: {
					click: () => {}
				}
			},
				img({
					attrs: {
						src: `data:image/gif;base64,${image}`
					},
					style: {
						textAlign: 'center',
						maxWidth: '100%',
						maxHeight: '100%'
					}
				}))
			)
		),
		div(`.col-xs-4.col-md-2`, a(`.thumbnail[title="add new image"]`, {
			on: {
				click: () => fileUtil.openDialog(files =>
					fileUtil.load(files[0], 'arrayBuffer').subscribe(content => {
						let base64String = btoa(new Uint8Array(content).reduce(
							(data, byte) => `${data}${String.fromCharCode(byte)}`, ''));
						console.log(update);
						update(field.name.split('.'), [].concat(value, base64String));
						console.log(content, base64String);
					})
				)
			}
		},
			i(`.fa.fa-plus`, {
				style: {
					textAlign: 'center',
					fontSize: '2em',
					width: '100%',
					height: '36px',
					lineHeight: '36px'
				}
			})))
	))
]);
