'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, a, span, img,
	table, thead, tbody, tr, td, th, form, input, button, label
} = require('iblokz-snabbdom-helpers');

const {obj} = require('iblokz-data');

const pages = {
	default: require('./pages/home'),
	about: require('./pages/about'),
	admin: require('./pages/admin'),
	articles: require('./pages/articles'),
	events: require('./pages/events'),
	projects: require('./pages/projects'),
	links: require('./pages/links')
};

module.exports = ({state, actions}) => section('.content', [
	obj.switch(state.router.path, pages)({state, actions})
]);
