module.exports={
	"views": {
		"articles": {
			"list": {
				"columns": ["title", "author", "categories", "status", "createdOn", "createdBy"]
			}
		},
		"events": {
			"list": {
				"columns": [
					"name",
					{"name": "startDateTime", "title": "start" },
					{"name": "endDateTime", "title": "end" },
					"createdOn",
					"createdBy"
				]
			}
		}
	},
	"collections": {
		"users": {
			"model": "User"
		},
		"articles": {
			"model": "Article",
			"schema": {
				"title" : { "type": "String", "required": true, "minLength": 1, "maxLength": 100, "unique": true },
				"slug": "String",
				"text" : "String",
				"status": { "type": "String", "enum": ["draft", "published"] },
				"author": "String",
				"categories": ["String"],
				"pinned": "Boolean",
				"createdBy" : { "type": "ObjectId", "ref":"User", "required": false},
				"createdOn": { "type": "Date", "default": "Date.now" }
			}
		},
		"events": {
			"model": "Event",
			"schema": {
				"name": "String",
				"slug": "String",
				"startDateTime": "Date",
				"endDateTime": "Date",
				"url": "String",
				"text": "String",
				"createdBy" : { "type": "ObjectId", "ref":"User", "required": false},
				"createdOn": { "type": "Date", "default": "Date.now" }
			}
		},
		"pages": {
			"model": "Page",
			"schema": {
				"title": "String",
				"path": "String",
				"content": "String",
				"createdBy" : { "type": "ObjectId", "ref":"User", "required": false},
				"createdOn": { "type": "Date", "default": "Date.now" }
			}
		}
	},
	"routes": {
		"api" : {
			"_meta": {
				"virtual": true,
				"crud": true,
				"contentType": "json"
			},
			"users": {
				"_meta": {
					"access": {
						"admin": "all",
						"publisher": "own",
						"user": "list,read",
						"anonymous": "create"
					}
				}
			},
			"articles": {
				"_meta": {
					"access": {
						"admin": "all",
						"publisher": "own",
						"user": "list,read",
						"anonymous": "list,read"
					}
				}
			},
			"events": {
				"_meta": {
					"access": {
						"admin": "all",
						"publisher": "own",
						"user": "list,read",
						"anonymous": "list,read"
					}
				}
			},
			"pages": {
				"_meta": {
					"access": {
						"admin": "all",
						"publisher": "own",
						"user": "list,read",
						"anonymous": "list,read"
					}
				}
			}
		}
	}
}
