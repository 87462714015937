'use strict';

// lib
const Rx = require('rx');
const $ = Rx.Observable;

// iblokz
const vdom = require('iblokz-snabbdom-helpers');
const {obj, arr} = require('iblokz-data');

// app
const app = require('./util/app');
let actions = app.adapt(require('./actions'));
let ui = require('./ui');
let actions$;
const state$ = new Rx.BehaviorSubject();

// services
// auth
let auth = require('./services/auth');
actions = app.attach(actions, 'auth', auth.actions);
// router
let router = require('./services/router');
actions = app.attach(actions, 'router', router.actions);
// rest
let rest = require('./services/rest');
// actions = app.attach(actions, 'rest', rest.actions);
// resource
let resource = require('./services/resource.js');
let resources = Object.keys(rest.config.routes.api)
	.filter(route => route !== '_meta'); // ['items', 'categories', 'locations'];
actions = resources.reduce(resource.attach, actions);

// hot reloading
if (module.hot) {
	// actions
	actions$ = $.fromEventPattern(
    h => module.hot.accept("./actions", h)
	).flatMap(() => {
		actions = app.adapt(require('./actions'));
		return actions.stream.startWith(state => state);
	}).merge(actions.stream);
	// ui
	module.hot.accept("./ui", function() {
		ui = require('./ui');
		actions.stream.onNext(state => state);
	});
	// services
	// auth
	module.hot.accept("./services/auth.js", function() {
		auth.unhook();
		auth = require('./services/auth.js');
		actions = app.attach(actions, 'auth', auth.actions);
		auth.hook({state$, actions});
		actions.ping();
	});
	// router
	module.hot.accept("./services/router.js", function() {
		router.unhook();
		router = require('./services/router.js');
		actions = app.attach(actions, 'router', router.actions);
		router.hook({state$, actions});
		actions.ping();
	});
	// rest
	module.hot.accept("./services/rest.js", function() {
		console.log('updating rest');
		rest.unhook();
		// console.log('updating render3d');
		rest = require('./services/rest.js');
		resources = Object.keys(rest.config.routes.api).filter(route => route !== '_meta');
		actions = resources.reduce(resource.attach, actions);
		rest.hook({state$, actions});
		// actions.set('needsRefresh', true);
		// state$.connect();
	});
	// booya
	// resource
	module.hot.accept("./services/resource.js", function() {
		resource.unhook();
		resource = require('./services/resource.js');
		actions = resources.reduce(resource.attach, actions);
		resources.forEach(resource.hook({state$, actions}));
		actions.ping();
	});
} else {
	actions$ = actions.stream;
}

// actions -> state
actions$
	.map(action => (
		action.path && console.log(action.path.join('.'), action.payload),
		console.log(action),
		action
	))
	.startWith(() => actions.initial)
	.scan((state, change) => change(state), {})
	.map(state => (console.log(state), state))
	.subscribe(state => state$.onNext(state));

// state change hooks
auth.hook({state$, actions});
router.hook({state$, actions});
rest.hook({state$, actions});
resources.forEach(resource.hook({state$, actions}));

// state -> ui
const ui$ = state$.map(state => ui({state, actions}));
vdom.patchStream(ui$, '#ui');

// livereload impl.
if (module.hot) {
	document.write(`<script src="http://${(location.host || 'localhost').split(':')[0]}` +
	`:${process.env.LVRLD_PORT || 35734}/livereload.js?snipver=1"></script>`);
}
