'use strict';

let pocket = {};

const set = (key, value) => typeof window !== 'undefined'
	? window.localStorage.setItem(key, JSON.stringify(value))
	: Object.assign(pocket, {[key]: value});
const get = (key, defaultValue) => typeof window !== 'undefined'
	? window.localStorage.getItem(key)
		&& JSON.parse(window.localStorage.getItem(key)) || defaultValue
	: typeof pocket[key] !== 'undefined' ? pocket[key] : defaultValue;

module.exports = {
	set,
	get
};
