'use strict';

// dom
const {
	h1, a, div, header,
	section, button, span,
	ul, li, i,
	table, thead, tbody, tr, th, td
} = require('iblokz-snabbdom-helpers');

const {obj, fn, str} = require('iblokz-data');

const moment = require('moment');

const log = msg => arg => (console.log(msg, arg), arg);

const getName = field => field.name || field.title || field;

module.exports = ({fields = [], list = [], actions = [], mods = {}}) => table('.grid', [
	thead(
		tr([].concat(
			fields.map(field => th(
				field.title || field
			)),
			th('Actions')
		)
	)),
	tbody(list.map(doc =>
		tr([].concat(
			fields
				.map(field => ({...field, value: doc[field.name || field] || ''}))
				.map(log('value: '))
				.map(field => ({
					...field,
					value: field.value instanceof Array ? field.value.map(getName).join(', ') : getName(field.value)
				}))
				.map(({name, value, schema = {}}) =>
					(mods[name] && mods[name] instanceof Function)
						? td(mods[name](value))
						: obj.switch(schema.type, {
							Date: td([value && moment(value).format('ll HH:mm') || 'n/a']),
							Boolean: td({attrs: {align: 'center'}}, i('.fa', {
								class: {
									'fa-check-square-o': value,
									'fa-square-o': !value
								}
							})),
							default: td([value])
						})
				),
			td('[align="center"]', actions.reduce((arr, act) => [].concat(
				arr,
				act.href
					? a(`${act.href}${act.sel || ''}`, {
						style: {
							// width: (100 / actions.length).toFixed(2) + '%'
						}
					}, act.title || '')
					: button(`${act.sel || '.plchldr'}`, {
						style: {
							// width: (100 / actions.length).toFixed(2) + '%'
						},
						on: {
							click: ev => act.cb({ev, doc})
						}
					}, act.title || '')
			), []))
		))
	))
]);
