'use strict';

// dom
const {
	h1, h2, a, div, header, p,
	section, button, span
} = require('iblokz-snabbdom-helpers');

module.exports = ({state, actions}) => section('.home', [
	h2('Добре дошли в MusicTechBG'),
	div([
		p(`
			Добре дошли в страницата на MusicTechBG.
		`),
		p(`
			Тук ще намерите полезни статии, уроци, връзки
			на различни теми, свързани с музикалните технологии.
		`),
		p(`
			Доста често организираме и събития,
			на които се събираме и дискутираме интересни новости, някой може да разкаже за последните
			си дръзновения. Носиме си нови и не толкова нови джаджи и понякога става джем.
		`)
	])
]);
