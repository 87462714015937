'use strict';

// dom
const {
	h1, a, div, header,
	section, button, span,
	ul, li, br, img, i,
	table, thead, tbody, tr, th, td,
	form, input, label, fieldset, legend,
	select, option, textarea
} = require('iblokz-snabbdom-helpers');

// lib
const {Observable: $} = require('rx');
const {obj, str, arr, fn: {pipe}} = require('iblokz-data');

// util
const dom = require('../../../util/dom');
const fileUtil = require('../../../util/file');

const md = require('../../../util/md');
const caret = require('../../../util/caret');

const set = (o, k, v) => Object.assign(o, {[k]: v});
const trim = s => s.trim();

const fieldToTitle = field => str.fromCamelCase(field, '-')
	.split('-').map(word => str.capitalize(word)).join(' ');
const getParent = (el, tagName) => ([].concat(tagName).indexOf(el.tagName) === -1)
	? getParent(el.parentNode, tagName) : el;

module.exports = ({field, value: {content = '', pos = {
	start: {row: 0, col: 0},
	end: {row: 0, col: 1}
}, mode = 'wysiwyg'} = {}, update = () => {}, focused = false, addons = []}) => (
	(console.log('wysiwyg', field, {content, pos, mode}, update)),
	div('.field', [
		field.name.split('.').length === 1 ? label([
			fieldToTitle(field.name.split('.').pop()),
			': '
		]) : '',
		div(`.wysiwyg[data-mode="${mode}"]`, [
			div('.toolbox', [].concat(
				ul(`.left`, [].concat(
						li(button('.btn.btn-white.fa.fa-bold', {
							on: {click: ev => (ev.preventDefault(), document.execCommand('bold'))}})),
						li(button('.btn.btn-white.fa.fa-italic', {
							on: {click: ev => (ev.preventDefault(), document.execCommand('italic'))}})),
						li(button('.btn.btn-white.fa.fa-underline', {
							on: {click: ev => (ev.preventDefault(), document.execCommand('underline'))}})),
						li('  '),
						li(button('.btn.btn-white.fa.fa-list-ul', {
							on: {click: ev => (ev.preventDefault(), document.execCommand('insertUnorderedList'))}})),
						li(button('.btn.btn-white.fa.fa-list-ol', {
							on: {click: ev => (ev.preventDefault(), document.execCommand('insertOrderedList'))}})),
						li('  '),
						li(button('.btn.btn-white.fa.fa-outdent', {
							on: {click: ev => (ev.preventDefault(), document.execCommand('outdent'))}})),
						li(button('.btn.btn-white.fa.fa-indent', {
							on: {click: ev => (ev.preventDefault(), document.execCommand('indent'))}}))
					)),
					ul('.right', [].concat(
						li(button(`.btn.btn-white.fa.fa-code`, {
							class: {active: mode === 'markdown'},
							on: {click: ev => (ev.preventDefault(), update(field.name.split('.'), {content, pos,
								mode: mode === 'wysiwyg' ? 'markdown' : 'wysiwyg'
							}))}
						})),
						addons.map(addon =>
							li(button(`.btn.btn-white.fa.${addon.icon}[type="button"]`, {
								on: {
									click: ev => addon.cb(field, {content: md.fromHTML(getParent(ev.target, 'DIV').parentNode
										.querySelector('.wysiwyg').innerHTML)}
									)
								}
							}))
						)
					))
			)),
			div('[contenteditable="true"]', {
				style: {
					// width: '100%',
					// minHeight: '320px',
					// border: '1px solid #ddd',
					// boxShadow: '1px 1px 5px #eee',
					// outline: 'none',
					// padding: '10px',
					// background: '#fff'
				},
				hook: {
					insert: ({elm}) => mode === 'wysiwyg' && focused
						&& caret.set(elm, pos, 'p, li, div, h1, h2, h3, h4, h5'),
					update: ({elm}) => mode === 'wysiwyg' && focused
						&& caret.set(elm, pos, 'p, li, div, h1, h2, h3, h4, h5')
				},
				props: {innerHTML: md.toHTML(content || '<p>&nbsp;</p>')},
				on: {
					focus: ({target}) => $.fromEvent(target, 'input')
						.map(ev => ev.target)
						.takeUntil($.fromEvent(target, 'blur'))
						.debounce(500)
						.subscribe(el =>
							update(
								field.name.split('.'),
								// value,
								{
									content: md.fromHTML(el.innerHTML),
									pos: caret.get(el, 'p, li, div, h1, h2, h3, h4, h5'),
									mode
								}
							)
						)
				}
			}),
			textarea(`[name="${field}"]`, {
				on: {
					focus: ({target}) => $.fromEvent(target, 'input')
						.map(ev => ev.target)
						.takeUntil($.fromEvent(target, 'blur'))
						.debounce(500)
						.subscribe(({value}) => update(
							field.name.split('.'),
							// value,
							{
								content: value,
								pos,
								mode
							}
						))
				},
				props: {
					innerHTML: content || ''
				}
			})
		])
	])
);
