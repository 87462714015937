'use strict';

// dom
const {
	h1, a, div, header, p, i, img,
	section, button, span, ul, li
} = require('iblokz-snabbdom-helpers');
// components
const grid = require('../../../comp/grid');

const {obj} = require('iblokz-data');

const orderWeight = [
	'_id', 'name', 'title',
	'function', 'type',
	'values', 'default',
	'author', 'start', 'end'
];

const skipFields = ['description', 'info'];

const schemaToFields = schema => ['_id'].concat(Object.keys(schema))
	.sort((a, b) => orderWeight[b] - orderWeight[a])
	.map(field => obj.switch(field, {
		default: () => field,
		_id: () => ({name: '_id', title: '#'})
	})());

module.exports = ({state, actions}) => [
	button('.btn', {on: {click: () => actions.router.go(`admin.${state.router.path[1]}.new`)}}, [
		i('.fa.fa-file-o'),
		'Create'
	]),
	grid({
		fields: state.rest.views[state.router.path[1]]
			? state.rest.views[state.router.path[1]].list.columns
			: schemaToFields(state.rest.collections[state.router.path[1]].schema),
		list: state[state.router.path[1]].list,
		actions: [
			// {
			// 	sel: '.fa.fa-eye',
			// 	cb: ({doc, ev}) => {}
			// },
			{
				sel: '.grid-action-button',
				// title: 'edit',
				title: img(`[src='/assets/icons/edit.svg']`),
				cb: ({doc, ev}) => actions.router.go(`admin.${state.router.path[1]}.${doc._id}`)
			},
			{
				sel: '.grid-action-button',
				// title: 'delete',
				title: img(`[src='/assets/icons/delete.svg']`),
				cb: ({doc, ev}) => actions[state.router.path[1]].delete(doc._id, state.auth.token)
			}
		]
	})
];
