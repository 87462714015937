'use strict';

// dom
const {
	h1, a, div, header,
	section, button, span,
	ul, li, br,
	table, thead, tbody, tr, th, td,
	form, input, label, fieldset, legend,
	select, option, textarea
} = require('iblokz-snabbdom-helpers');

// lib
const moment = require('moment');
const {obj, str, arr, fn: {pipe}} = require('iblokz-data');
const dom = require('../../../../util/dom');

const set = (o, k, v) => Object.assign(o, {[k]: v});
const trim = s => s.trim();

const fieldToTitle = field => str.fromCamelCase(field, '-')
	.split('-').map(word => str.capitalize(word)).join(' ');

const syncWidth = (text, parent, list) => (
	console.log(text, parent, list),
	set(text, 'style', `width: ${parent.clientWidth - list.clientWidth - 3}px`)
);

const removeVal = (target, val) => pipe(
	() => dom.select(target, `.form-multiple > input[type="hidden"]`, true),
	el => (console.log(target, el), el),
	el => el && (
		set(el, 'value', arr.toggle(el.value.split(',').map(trim), val).join(', ')),
		console.log(target.parentNode.parentNode, target.parentNode),
		setTimeout(() => syncWidth(
			dom.select(el, '.tail', true),
			el.parentNode,
			dom.select(el, 'ul', true)
		)),
		target.parentNode.parentNode.removeChild(target.parentNode)
	)
)();

module.exports = ({field, value = []}) => div(`.field`, [
	field.name.split('.').length === 1 ? label([
		fieldToTitle(field.name.split('.').pop()),
		': '
	]) : '',
	div(`.form-multiple`, [
		input(`[type="hidden"][name="${field.name}"][value="${value.join(', ')}"]`, {
			on: {
				change: ev => syncWidth(
					dom.select(ev.target, '.tail', true),
					dom.select(ev.target, '.form-multiple', true),
					dom.select(ev.target, 'ul', true)
				)
			}
		}),
		ul(value.map(val =>
			li([
				span(val),
				button(`.fa.fa-times-circle`, {
					on: {
						click: ev => removeVal(ev.target, val)
					}
				})
			])
		)),
		input(`.tail[type="text"]`, {
			on: {
				input: ev => ev.data === ','
					? pipe(
						() => ({
							el: dom.select(ev.target, `.form-multiple > input[type="hidden"]`, true),
							value: ev.target.value.replace(',', '').trim()
						}),
						({el, value}) => (
							set(el, 'value', arr.add(el.value.split(',').map(trim), value).join(', ')),
							dom.append(dom.select(el.parentNode, 'ul'), dom.create('li', {
								innerHTML: `<span>${value}</span><button class="fa fa-times-circle"></button>`
							})),
							dom.on(dom.select(el.parentNode, 'ul > li:last-child > button'), 'click', ev => removeVal(ev.target, value)),
							syncWidth(
								ev.target,
								ev.target.parentNode,
								dom.select(el.parentNode, 'ul')
							)
						),
						() => set(ev.target, 'value', '')
					)() : []
			},
			hook: {
				insert: ({elm}) => syncWidth(elm, elm.parentNode, dom.select(elm, 'ul', true)),
				update: ({elm}) => syncWidth(elm, elm.parentNode, dom.select(elm, 'ul', true))
			}
		})
	])
]);
