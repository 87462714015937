'use strict';

const Rx = require('rx');
const $ = Rx.Observable;
const {obj} = require('iblokz-data');

const initial = {
	mode: 'history', // history | hash
	page: 'home',
	path: ['home'],
	admin: false,
	pageId: null
};

const parsePageParams = str => {
	const path = str.split('/');
	const admin = path[0] === 'admin';
	const pageId = path.length > (1 + admin ? 1 : 0) ? path.slice(admin ? 2 : 1).join('/') : null;
	const page = (admin ? path.slice(0, 2).join('.') : path[0]) || 'home';
	return {
		path,
		admin,
		page,
		pageId
	};
};

let change;
let go;

change = page => state => (page.match('admin') && (!state.auth.user
		|| ['admin', 'editor'].indexOf(state.auth.user.role) === -1))
	? (go('home'), state)
	: obj.patch(state, 'router', parsePageParams(page));

// go action, handled within the service hook
go = page => state => state;

const actions = {
	initial,
	change,
	go
};

const clearSlashes = path => path.toString().replace(/\/$/, '').replace(/^\//, '');
const getFragment = ({mode, root = '/'}) => {
	let fragment = '';
	if (mode === 'history') {
		fragment = clearSlashes(decodeURI(location.pathname + location.search));
		fragment = fragment.replace(/\?(.*)$/, '');
		fragment = root !== '/' ? fragment.replace(root, '') : fragment;
	} else {
		var match = window.location.href.match(/#(.*)$/);
		fragment = match ? match[1] : '';
	}
	return clearSlashes(fragment);
};

let unhook = () => {};
const hook = ({state$, actions}) => {
	let subs = [];

	const hashChange$ = $.fromEvent(window, 'hashchange');
	const popState$ = $.fromEvent(window, 'popstate');

	// const initialUrlSetSub =
	// 	state$.take(1)
	// 		.subscribe(state => {
	// 			console.log(123, state.router, location);
	// 			let url = getFragment({mode: state.router.mode});
	// 			console.log({url});
	// 			actions.router.change(url);
	// 		});
	// subs.push(initialUrlSetSub);

	// location change subscription
	const locationChangeSub = $.merge(
		hashChange$.map(() => ({type: 'hash'})),
		popState$.map(() => ({type: 'history'})),
		$.just({type: 'initial'})
	)
		.withLatestFrom(state$, (evt, state) => ({evt, state}))
		// .filter(({state, evt}) => (
		// 	console.log(state, evt),
		// 	evt.type === 'initial' || (evt.type === state.router.mode)
		// ))
		.subscribe(({state, evt}) => {
			let mode = evt.type === 'initial' ? state.router.mode : evt.type;
			let url = getFragment({mode});
			if (mode !== state.router.mode)
				actions.router.go(url.split('/').join('.'));
			else
				actions.router.change(url);
		});

	// location change subscription
	// const locationChangeSub = state$.take(1).subscribe(() => {
	// 	window.setTimeout(() => actions.router.change(location.hash.replace('#/', '') || 'home'));
	// 	window.addEventListener('hashchange',
	// 		() => actions.router.change(location.hash.replace('#/', '') || 'home'));
	// });
	subs.push(locationChangeSub);
	// go action handler
	const actionsHandlerSub = actions.router.stream
		.withLatestFrom(state$, (action, state) => ({action, state}))
		.subscribe(({action, state}) => obj.switch(action.path.join('.'), {
			'default': () => {}, // console.log(action.path, action.payload),
			'router.go': () => {
				let page = action.payload[0];
				let url = (page !== 'home') ? page.split('.').join('/') : '';
				console.log(action.path, page, url, state.router);
				if (state.router.mode === 'hash')
					window.location.hash = `/${url}`;
				else {
					window.history.pushState({page}, `${page}`, `/${url}`);
					actions.router.change(url);
				}
			}
		})());
	subs.push(locationChangeSub);

	let unhook = () => subs.forEach(sub => sub.dispose());
	return unhook;
};

module.exports = {
	actions,
	hook,
	unhook: () => unhook()
};
