'use strict';

// dom
const {
	h1, a, div, header,
	section, button, span,
	ul, li, br, i, hr,
	table, thead, tbody, tr, th, td,
	form, input, label, fieldset, legend,
	select, option, textarea
} = require('iblokz-snabbdom-helpers');

// lib
const moment = require('moment');
const {obj, str, arr} = require('iblokz-data');
const formUtil = require('../../../util/form');

// fieldTypes
const multiple = require('./multiple');
const file = require('./file');
const wysiwyg = require('./wysiwyg');
// const tinymce = require('./tinymce');

const sub = (o, p, d = false) =>
	o === undefined
		? d
		: (p instanceof Array)
			? o[p[0]] && p.length > 1
				? [sub(o[p[0]], p.slice(1))].map(sO => (sO !== undefined ? sO : d)).pop()
				: o[p[0]]
			: o[p] !== undefined ? o[p] : d;

// const schema = {
// 	name: {type: 'String', min: 3, max: 30},
// 	type: {type: 'String', enum: ['polygon', 'circle'], default: 'polygon'},
// 	path: [{
// 		lat: 'Number',
// 		lng: 'Number'
// 	}],
// 	center: {
// 		lat: 'Number',
// 		lng: 'Number'
// 	},
// 	radius: 'Number'
// };

const schemaInputFieldMap = {
	String: 'text',
	Number: 'number',
	Date: 'datetime-local',
	Boolean: 'checkbox'
};

const keys = o => Object.keys(o);

const decorateField = field => obj.switch(field.type, {
	default: field,
	String: ['content', 'article', 'post', 'text'].indexOf(field.name) > -1
		? {...field, type: 'RichText'}
		: field
});

const parseField = (field, name) => (
	// console.log(field, name),
	(typeof field === 'string')
		? {type: field, name, required: true}
		: field.type
			? Object.assign({}, field, {name})
			: field instanceof Array
				? {type: 'Array', name, schema: parseField(field[0])}
				: field instanceof Object
					? {type: 'Object', name, fields: keys(field).map(key => parseField(field[key], [name, key].join('.')))}
					: false
	);

	//  keys(field).map(key => parseField(field[key], [name, key].join('.')))

const fieldToTitle = field => str.fromCamelCase(field, '-')
	.split('-').map(word => str.capitalize(word)).join(' ');

const getParent = (el, tagName) => ([].concat(tagName).indexOf(el.tagName) === -1)
	? getParent(el.parentNode, tagName) : el;

const inputAddons = (content, field, addons) => addons.length > 0 && field.type === 'String'
	? div('.input-group', [
		content,
		div('.input-group-btn', addons.map(addon =>
			button(`.btn.btn-default[type=button]`, {
				on: {
					click: ev => addon.cb(field, getParent(ev.target, 'DIV').parentNode.querySelector('input').value)
				}
			}, [i(`.fa.${addon.icon}`)])
		))
	])
	: content;

const formElement = ({field, doc, update = () => {}, focused = false, addons = []}) => (
	// console.log(field, doc),
	obj.switch(field.type, {
		File: value => file({field, value, update, focused}),
		RichText: value => wysiwyg({field, value: value && value.content ? value : {content: value}, update, focused, addons}),
		Text: value => div('.field', [
			field.name.split('.').length === 1 ? label([
				fieldToTitle(field.name.split('.').pop()),
				': '
			]) : '',
			textarea(
				{
					style: {width: '100%', minHeight: '160px', outline: 'none'},
					on: {input: ev => update(field.name.split('.'), ev.target.value)},
					attrs: {name: field.name, disabled: field.disabled, hidden: field.hidden},
					props: {value}
				}
			)
		]),
		Mixed: value => div('.field', [
			field.name.split('.').length === 1 ? label('.col-sm-2.control-label', [
				fieldToTitle(field.name.split('.').pop()),
				': '
			]) : '',
			textarea(
				{
					on: {input: ev => update(field.name.split('.'), ev.target.value)},
					attrs: {name: field.name, disabled: field.disabled, hidden: field.hidden}
				},
				typeof value === 'string' ? value : JSON.stringify(value)
			)
		]),
		Array: value => field.schema.type === 'String'
			? multiple({field, value, update})
			: formElement({...field, type: 'mixed'}, doc, update),
		default: value => div('.field', [
			field.name.split('.').length === 1
				? label([
					fieldToTitle(field.name.split('.').pop()),
					': '
				]) : '',
			(field.type === 'String' && field.enum instanceof Array)
				? select({
					on: {change: ev => update(field.name.split('.'), ev.target.value)},
					attrs: {name: field.name, disabled: field.disabled}
				}, [].concat(
						// option('[value=""]', 'n/a'),
						field.enum.map((val, index) => option({
							attrs: {
								value: val,
								selected: typeof value === 'undefined' && index === 0 || val === value
							},
							props: {
								value: val,
								selected: typeof value === 'undefined' && index === 0 || val === value
							}
						}, val)
					)))
				: (field.type === 'ObjectId' && field.hash instanceof Object)
					? select({
						on: {change: ev => update(field.name.split('.'), ev.target.value)},
						attrs: {name: field.name, disabled: field.disabled}
					}, [].concat(
						// option('[value=""]', 'n/a'),
						Object.keys(field.hash).map((id, index) =>
							option({
								attrs: {
									value: id,
									selected: typeof value === 'undefined' && index === 0 || id === value
								},
								props: {
									value: id,
									selected: typeof value === 'undefined' && index === 0 || id === value
								}
							}, field.hash[id]
						)
					)))
				: input({
					on: {input: ev => update(field.name.split('.'), ev.target.value)},
					attrs: {
						name: field.name,
						type: field.name.match('password') ? 'password' : schemaInputFieldMap[field.type],
						step: 0.01,
						placeholder: fieldToTitle(field.name.split('.').pop()),
						checked: field.type === 'Boolean' && value === true,
						disabled: field.disabled
					},
					props: {
						value: field.type === 'Date'
							? moment(value).format('YYYY-MM-DDTHH:mm')
							: value || ''
					}
				})
			// field.description
			// 	? span(`.help-block.m-b-none`, field.description)
			// 	: []
		]),
		Object: () => div('.field', [].concat(
			label('', [
				fieldToTitle(field.name.split('.').pop()),
				': '
			]),
			field.fields.map(f => formElement(f, doc))
		))
	})(sub(doc, field.name.split('.'), ''))
);

module.exports = ({schema, doc = {}, sel = '',
	noactions = false, actions: {save, cancel, update = () => {}},
	focused = false,
	addons = []
}) => form(sel, {
	on: {
		submit: ev => (
				ev.preventDefault(),
				save({data: formUtil.toData(ev.target), ev})
			)
	}
}, [].concat(
	keys(schema)
		.map(key => parseField(schema[key], key))
			.map(decorateField)
      .reduce((els, field) => [].concat(
				els,
				formElement({field, doc, update, focused: focused === field.name, addons})
				// hr()
			), []),
	!noactions ? [
		button(`.btn.btn-white[type="button"]`, {on: {click: ev => cancel({ev})}}, 'Cancel'),
		' ',
		button(`.btn.btn-primary[type="sumbit"]`, 'Save')
	] : []
));
