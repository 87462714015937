'use strict';

const toMarkdown = require('to-markdown');
const marked = require('marked');

const options = {
	toMarkdown: {
		converters: [
			{
				filter: 'li',
				replacement: (content, node) => {
					content = content.replace(/^\s+/, '').replace(/\n/gm, '\n ');
					let prefix = '- ';
					const parent = node.parentNode;
					const index = Array.prototype.indexOf.call(parent.children, node) + 1;

					prefix = /ol/i.test(parent.nodeName) ? index + '. ' : '- ';
					return prefix + content;
				}
			},
			{
				filter: 'p',
				replacement: (content, node) => node.parentNode.tagName !== 'LI'
					? `\n\n${content}` : content
			}
		]
	},
	marked: {
		gfm: true,
		breaks: false,
		smartLists: true
	}
};

const toHTML = md => (console.log(md, marked(md, options.marked)),
	marked(md, options.marked)
		.replace(/<div>/ig, '<p>')
		.replace(/<\/div>/ig, '</p>')
		.replace(/<li><p>/ig, '<li>')
		.replace(/<\/p>(\n)?<\/li>/ig, '</li>')
		.replace(/<p><\/p>/ig, ''));

const fromHTML = html => (
	console.log(html),
	toMarkdown(html, options.toMarkdown)
);

module.exports = {
	options,
	toHTML,
	fromHTML
};
