'use strict';

// dom
const {
	h1, a, div, header, p,
	section, button, span
} = require('iblokz-snabbdom-helpers');
// comp
const breadcrumb = require('./breadcrumb');
const resources = require('./resources');

const {obj} = require('iblokz-data');

module.exports = ({state, actions}) => section('.admin', [
	breadcrumb({state, actions}),
	state.rest.routes.api[state.router.path[1]]
		? resources({state, actions})
		: div(p('Welcome to admin'))
]);
