'use strict';

const {
	section, h1, h2, h3, hr, header, i, ul, li, a, span, img,
	table, thead, tbody, tr, td, th, form, input, button, label
} = require('iblokz-snabbdom-helpers');

const formUtil = require('../../util/form');

const menu = {
	front: [
		{page: 'home', href: '#/', title: 'Начало'},
		{page: 'about', href: '#/about', title: 'За Нас'},
		{page: 'articles', href: '#/articles', title: 'Статии'},
		{page: 'calendar', href: '#/calendar', title: 'Календар'},
		{page: 'projects', href: '#/projects', title: 'Проекти'},
		{page: 'resources', href: '#/resources', title: 'Полезни Ресурси'}
	],
	admin: [
		{page: 'admin.home', href: '#/admin', title: 'Табло'},
		{page: 'admin.pages', href: '#/admin/pages', title: 'Страници'},
		{page: 'admin.articles', href: '#/admin/articles', title: 'Статии'},
		{page: 'admin.events', href: '#/admin/events', title: 'Събития'},
		{page: 'admin.media', href: '#/admin/media', title: 'Снимки и Видео'},
		{page: 'admin.users', href: '#/admin/users', title: 'Потребители'}
	]
};

module.exports = ({state, actions}) => header([
	button('.left.menu-toggle', {
		on: {click: () => actions.toggleMenu('main')},
		class: {toggled: state.menu === 'main'}
	}, [
		img(`[src="/assets/icons/${state.menu === 'main' ? 'cancel' : 'menu'}.svg"]`)
	]),
	img('.logo[src="/assets/mbg.svg"]'),
	(!state.auth.user)
		? a('.right.login-menu-toggle', {
			on: {click: () => actions.toggleMenu('log-in')},
			class: {toggled: state.menu === 'log-in'}
		}, [
			img(`[src="/assets/icons/${state.menu === 'log-in' ? 'cancel' : 'write'}.svg"]`)
		])
		: button(`.right.log-out`, {
			on: {click: () => actions.auth.logout()}
		}, [
			img(`[src="/assets/icons/log-out.svg"]`)
		]),
	ul('.main-menu', [].concat(
		(state.auth.user) && li(
			(state.router.admin)
				? a('[href="#/"][title="Начална страница"]', [
					img(`[src="/assets/icons/newspaper.svg"]`)
				])
				: a('[href="#/admin"][title="Администрация"]', [
					img(`[src="/assets/icons/dashboard.svg"]`)
				])
		) || '',
		menu[[state.router.path[0] === 'admin' ? 'admin' : 'front']].map(item =>
			li(a(`[href="${item.href}"]`, {
				class: {active: item.page === state.router.page}
			}, item.title))
		)
	)),
	ul('.right', [
		li(
			(!state.auth.user)
					? [(state.menu === 'log-in')
						// login form
						? form({
							on: {
								submit: ev => {
									ev.preventDefault();
									let data = formUtil.toData(ev.target);
									actions.auth.login(data);
								}
							}
						}, [
							label('[for="login-email"]', [img(`[src="/assets/icons/user.svg"]`)]),
							input('#login-email[name="email"][placeholder="Потребителско име"]'),
							label('[for="login-pass"]', [img(`[src="/assets/icons/key.svg"]`)]),
							input('#login-password[name="password"][type="password"][placeholder="Парола"]'),
							button([
								img(`[src="/assets/icons/log-in.svg"]`)
							])
						])
						// toggle button
						: ''
					]
				: [
					span(state.auth.user.name || state.auth.user.email)
				])
	])
]);
