'use strict';

const Rx = require('rx');
const $ = Rx.Observable;
const Subject = Rx.Subject;
const request = require('superagent');
const store = require('../util/store');
const {obj} = require('iblokz-data');

// const page = require('../page');
console.log(process.env.PORT);
const port = ''; // process.env.PORT && process.env.PORT !== '80' ? `:${process.env.PORT}` : '';
const url = `//${location.host || process.env.SERVER_URL || 'localhost'}${port}`;
console.log({url});

const forceLogout = () => state => {
	store.set('user', null);
	store.set('token', null);
	setTimeout(() => {
		window.location = '#/';
	});
	return obj.patch(state, 'auth', {user: null, token: null});
};

const login = data => request
	.post(`${url}/api/auth/authorize`)
  .send(data)
	.set('Accept', 'application/json')
	.then(res => (console.log(res), res))
	.then(res => res.body)
	.then(({msg, user, token}) =>
		(user)
			? (
				store.set('user', user),
				store.set('token', token),
				state => obj.patch(state, 'auth', {user, token})
			)
			: state => state
	);

const register = data => request
	.post(`${url}/api/auth/register`)
  .send(data)
	.set('Accept', 'application/json')
	// .then(res => res.body)
	.then(({status}) =>
		(status === 201)
			? login(data)
			: state => state
	);

const logout = token => request
	.delete(`${url}/api/auth/token`)
	.query({token})
	.set('Accept', 'application/json')
	.then(res => res.body)
	.then(data =>
		(data.success)
			? forceLogout()
			: state => state
	);

const actions = {
	forceLogout,
	login,
	logout: forceLogout,
	register,
	initial: {user: store.get('user'), token: store.get('token')}
};

let unhook = () => {};
const hook = ({state$, actions}) => {
	let subs = [];
	subs.push(
		state$
			.distinctUntilChanged(state => state.auth.user)
			.filter(state => state.auth.user && state.auth.user.email)
			.subscribe(state => state.router.page === 'auth' && actions.router.go('home'))
	);

	unhook = () => subs.forEach(sub => sub.dispose());
};

module.exports = {
	actions,
	hook,
	unhook: () => unhook()
};
