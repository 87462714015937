'use strict';

const {obj, str, arr, fn: {pipe}} = require('iblokz-data');

// some dom manipulation functions here
// select html dom element
const select = (el, q, recursive = false) => pipe(
	() => el.querySelectorAll(q),
	// res => (console.log(el, res), res),
	res => (recursive
		&& !(res instanceof HTMLElement)
		&& res.length === 0 && el.parentNode)
			? select(el.parentNode, q, recursive)
			: res,
	res => res instanceof HTMLElement
		? res
		: res.length
			? Array.from(res) : [],
	res => res.length === 1 ? res[0] : res
)();

// append child to el
const append = (el, child) => (
	console.log(el),
  el.appendChild(child),
  el
);
// set prop to element
const set = (el, prop, value) => Object.assign(
  el, {[prop]: value}
);
// create dom element, apply props and add children
const create = (tag, props, children = []) =>
  children.reduce(
    (el, child) => append(el, child),
    Object.keys(props).reduce(
      (el, prop) => set(el, prop, props[prop]),
      document.createElement(tag.toUpperCase())
    )
  );
// attach event listener
const on = (el, event, callback) =>
  (el instanceof HTMLElement)
    ? el.addEventListener(event, callback)
    : (typeof el === 'string')
      ? pipe(
					() => select(document, el),
					els => els.length ? els.forEach(el =>
						on(el, event, callback)
					) : on(els, event, callback)
				)()
      : console.log(el);

module.exports = {
	select,
	create,
	append,
	on
};
