'use strict';

// dom
const {
	h1, a, div,
	section, button, span,
	img, ul, li
} = require('iblokz-snabbdom-helpers');
// components
const header = require('./header');
const layout = require('./layout');

module.exports = ({state, actions}) => section('#ui', [
	header({state, actions}),
	layout({state, actions})
]);
